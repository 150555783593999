.newPassContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.topContent {
    justify-content: flex-end;
}

.bottomContent {
    justify-content: space-between;
}

.topContent, .bottomContent {
    display: flex;
    align-items: center;
}

.readOnlyContainer {
    display: flex;
    margin-top: 12px;
}

.closeButton {
    padding: 4px;
    margin-left: 16px;
}

.updateButton {
    margin-top: 12px;
}