.editor {
    width: 100%;
    height: 100%;

    &.hide {
        display: none;
    }

    >.md {
        font-family: 'Fira Code'
    }
}

/* .editor.hide {
    display: none;
}

.editor .md {
    font-family: 'Fira Code'
} */

@media only screen and (max-width: 800px) {
    .hideMobile {
        display: none;
    }

    .rc-md-editor .editor-container {
        flex-direction: column;
    }

    .section.sec-html.visible {
        border-top: 1px solid black;
    }
}