.childrenContainer {
    display: flex;

    &.collapse {
        width: fit-content;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}

.wholePage {
    width: 100%;

    >.childrenInfo {
        width: inherit;
    }

    >.childrenCollapse {
        display: none;
    }

    >.childrenCreateChildren {
        justify-content: center;
    }
}

.childrenInfo {
    display: flex;
    flex-direction: column;
    width: min-content;
    height: 100%;
    background-color: rgb(238, 255, 238);
    transition: 1s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
        width: inherit;
    }

    .header {
        background-color: #2b6535;
        text-align: center;
        user-select: none;
    
        padding: 10px 0px;
    
        font-family: 'Righteous';
        text-decoration: none;
        color: #f0f8ff;
        font-size: 40px;
        line-height: 40px;
        user-select: none;

        >.link {
            font-family: 'Righteous';
            color: #f0f8ff;
            text-decoration: none;
            user-select: none;
            display: inline; /* should be 'inline' for multiple line animation */
            background-image: linear-gradient(to right, #f0f8ff, #9FD39C);
            background-size: 0% 3px;
            background-repeat: no-repeat;
            transition: background-size 0.3s;
            background-position: 0 calc(100% - 0px);
        
            &:hover {
                background-size: 100% 3px;
            }
        
            &.bold {
                font-weight: 700;
            }
        }

        >.najjarDocButton {
            position: absolute;
            right: calc((100vw - 1100px) / 2);
            top: 12px;

            fill: white;

            cursor: pointer;

            border-radius: 12px;

            @media only screen and (max-width: 800px) {
                right: 12px;
            }
        }

        @media only screen and (max-width: 800px) {
            padding: 10px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >.najjarDocButton {
                position: inherit;
            }
        }
    }

    .childrenButtonsContainer {
        box-sizing: border-box;
        padding: 0px 8px 8px;
        display: grid;
        grid-template-columns: auto auto;
    }

    .footer {
        background-color: #2b6535;
        text-align: end;
        user-select: none;
    
        padding: 10px 16px;
    
        text-decoration: none;
        color: #f0f8ff;
        user-select: none;

        :not(:first-child) {
            margin-top: 12px;
        }
        
        .text {
            text-align: end;
            user-select: none;
            text-decoration: none;
            color: #f0f8ff;
            user-select: none;


            >.hatch {
                font-size: 22px;
                
                .link {
                    font-family: 'Righteous';
                    color: #f0f8ff;
                    text-decoration: none;
                    user-select: none;
                    display: inline; /* should be 'inline' for multiple line animation */
                    background-image: linear-gradient(to right, #f0f8ff, #9FD39C);
                    background-size: 0% 3px;
                    background-repeat: no-repeat;
                    transition: background-size 0.3s;
                    background-position: 0 calc(100% - 0px);
                
                    &:hover {
                        background-size: 100% 3px;
                    }
                
                    &.bold {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.childrenInfo.collapse {
    display: none;
}

.childrenCollapse {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    cursor: pointer;
    padding: 0px 4px;
    height: 100%;
    border: 1px solid #e0e0e0;
    background-color: #e0e0e0;
    transition: 0.2s;

    &:hover {
        background-color: #d0d0d0;
    }

    @media only screen and (max-width: 800px) {
        width: fit-content;
    }
}

.menuActions {
    padding: 16px 8px 4px;

    .buttonSpace {
        margin-bottom: 12px;
        width: 100%;
    }

    .needPassText {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom: 12px;
    }

    .childrenCreateChildren {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        @media only screen and (max-width: 800px) {
            justify-content: center;
        }
    }
}

.breakLine {
    border-bottom: 1px solid gray;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.rootPageTextContainer {
    display: flex;
    justify-content: center;

    > .rootPageText {
        padding: 50px;
        font-size: 22px;
        width: 30%;
        text-align: center;

        @media only screen and (max-width: 800px) {
            padding: 20px 0px;
            font-size: 16px;
            width: 95%;
            text-align: center;
        }
    }
}